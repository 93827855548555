import api from '../index'

const branch = {
    list(data) {
        return api({
            url: 'v2/complaint/branch-list',
            method: 'post',
            data: data
        })
    },
    complaint(data) {
        return api({
            url: 'v2/complaint/save',
            method: 'post',
            data: data
        })
    },
}
export default branch