<!-- 问题反馈 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <div class="header-content">问题反馈</div>
    </header>
    <section class="page-info">
      <van-form class="info-form" @submit="onSubmit" v-model:loading="loading">
        <div style="margin: 0 15px;">
          <span class="van-title-custom"><span style="color: red;">*</span>请选择您要反馈的类型（必填）</span>
          <van-field class="van-field-custom" v-model="formData.typetext" readonly is-link name="type" label=""
            placeholder="选择类型" @click="showPicker = true" />
          <span class="van-title-custom">请选择部门名称/科室名称（非必填）</span>
          <van-field class="van-field-custom" v-model="formData.branch_name" readonly is-link name="branch_id" label=""
            placeholder="选择类型" @click="showBranchPicker = true" />
          <span class="van-title-custom">请填写您的姓名（非必填）</span>
          <van-field class="van-field-custom" v-model="formData.user_name" name="user_name" label=""
            placeholder="请输入姓名" />
          <span class="van-title-custom">请填写您的联系电话（非必填）</span>
          <van-field class="van-field-custom" v-model="formData.phone_num" name="phone_num" label=""
            placeholder="请输入联系电话" />
          <span class="van-title-custom">请填写您的电子邮箱（非必填）</span>
          <van-field class="van-field-custom" v-model="formData.email" name="email" label="" placeholder="请输入电子邮箱" />
          <span class="van-title-custom"><span style="color: red;">*</span>请填写反馈内容（必填）</span>
          <van-field class="van-field-custom" v-model="formData.content" name="content" label="" type="textarea" rows="4"
            autosize maxlength="200" placeholder="请输入反馈内容" />
          <span class="van-title-custom"><span style="color: red;">*</span>请输入验证码</span>
          <van-field class="van-field-custom code-class" v-model="formData.code_num" name="code_num" label=""
            placeholder="请输入验证码" />
          <imageCode class="code-image" :change="change_img_code" @click="changeImageCode" @getCode="backImageCode" />
        </div>
        <div class="bind-commit">
          <van-button type="primary" color="#9F88FF" native-type="submit" round block>提交</van-button>
        </div>
      </van-form>
      <!-- 弹窗 -->
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
      <van-popup v-model:show="showBranchPicker" position="bottom">
        <van-picker :columns="branchColumns" @confirm="onConfirmBranch" @cancel="showBranchPicker = false" />
      </van-popup>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent, getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Toast } from 'vant'
import md5 from 'js-md5';
import branch from '../../api/ofs/branch'
const imageCode = defineAsyncComponent(() => import('@/components/ImageCode/identify.vue'));
export default {
  name: "complaint",
  components: { imageCode },
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();
    const data = reactive({
      loading: false,
      change_img_code: false,
      img_code: '',
      formData: {
        complaint_id: 0,
        type: '',
        typetext: '',
        branch_id: '',
        branch_name: '',
        user_name: '',
        phone_num: '',
        email: '',
        content: '',
        check_state: 1,
        state: 1,
        code_num: '',
      },
      organ_id: 1,
      showPicker: false,
      showBranchPicker: false,
      columns: [
        { text: '投诉', value: '1' },
        { text: '感谢信', value: '2' },
        { text: '建议', value: '3' },
        { text: '其他', value: '4' },
      ],
      branchColumns: [
        { text: '营养科', value: '1' },
      ]
    });

    onMounted(() => {
      document.title = '问题反馈';
      const options = $router.currentRoute.value.query;
      console.log(options)
      data.organ_id = options.organ_id > 0 ? options.organ_id : 1
      const post_data = {
        keyword: '',
        organ_id: data.organ_id,
        source: 'patient_edit',
        page_index: 1,
        page_size: 100
      }
      branch.list(post_data).then(res => {
        data.branchColumns = res.data.data.map(item => {
          const vale = {
            text: item.branch_name,
            value: item.branch_id
          }
          return vale
        })
      })
    });

    const changeImageCode = () => {
      data.change_img_code = !data.change_img_code
    };

    const backImageCode = (code) => {
      data.img_code = code
    };

    const onConfirm = (selectedValues) => {
      data.formData.typetext = selectedValues.text;
      data.formData.type = selectedValues.value;
      data.showPicker = false;
    };

    const onConfirmBranch = (selectedValues) => {
      data.formData.branch_name = selectedValues.text;
      data.formData.branch_id = selectedValues.value;
      data.showBranchPicker = false;
    };

    const onSubmit = (values) => {
      console.log(data.formData)
      if (!data.formData.type || data.formData.type == 0) {
        Toast('请选择反馈类型');
        return
      }
      if (!data.formData.content || data.formData.content.length == 0) {
        Toast('请填写反馈内容');
        return
      }
      if (data.formData.code_num == '') {
        Toast('请输入验证码！');
        return;
      }
      let imgCode = md5(data.formData.code_num)
      if (imgCode != data.img_code) {
        changeImageCode();
        Toast('验证码错误！');
        return;
      }
      data.loading = true
      branch.complaint(data.formData).then(res => {
        data.loading = false
        $router.push({
          name: "complete",
        })
        data.formData = {
          complaint_id: 0,
          type: '',
          typetext: '',
          branch_id: '',
          branch_name: '',
          user_name: '',
          phone_num: '',
          email: '',
          content: '',
          check_state: 1,
          state: 1,
          code_num: '',
        }
      }).catch(err => {
        data.loading = false
        Toast(err.message);
      })
    };

    return {
      ...toRefs(data),
      onSubmit,
      onConfirm,
      onConfirmBranch,
      backImageCode,
      changeImageCode,
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;

  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .header-content {
      text-align: center;
      font-size: 18px;
      display: inline-block;
      flex: 1;
      font-weight: 700;
    }
  }

  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin-bottom: 10px;

    .info-form {
      margin-bottom: 80px;
    }

    .bind-commit {
      width: 100%;
      display: block;
      padding: 15px 15px 15px 15px;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .van-title-custom {
    display: block;
    padding: 16px 0px 10px;
    color: var(--van-doc-text-color-4);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .van-field-custom {
    background: #eee;
  }

  .code-class {
    display: inline-block;
    width: calc(100% - 130px);
  }

  .code-image {
    margin-left: 10px;
    height: 1.1rem;
    width: 120px;
  }
}
</style>
